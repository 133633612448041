body {
	/* font-family: 'Roboto Condensed', sans-serif; */
	font-family: 'Roboto Mono', monospace;
	font-weight: 300;
	font-size: 1rem;
}
  .mark {
  	max-width: 300px;
  	height: auto;
  	margin: 20px auto;
  }
  .mark svg {
  	fill: #FFF;
  }
  .shirt, .shirt img {
  	max-width: 300px;
  	margin: 20px auto;
  	text-align: center;
  	font-family: Helvetica, Arial, sans-serif;
  }
  a {
  	color: #333;
  }
  html {
  	background: linear-gradient(323deg, #6699cc, #336699, #333366);
	background-size: 100% 100%;
	min-height: 100%;
	}

h1, h2, h3, h4, h5 {
	margin: 0;
	padding: 0;
	line-height: 100%;
	font-weight: 600;
}
.gutter-sizer { width: 1%; }
.item {
background: #FFF;
margin-bottom: 2em;
 }
.content {
	padding: 1em;
	clear: both;
}
img {
	max-width: 100%;
	height: auto;
}
.item a {
	text-decoration: none;
}
.item:hover {
	background: #f0f0f0;
}
#container {
margin: 0 2%;
}

.links-out {
	text-align: center;
	margin: 0 0 1.5rem;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.footer {
	text-align: center;
	margin: 0 0 1rem;
}

ul li a {
	margin: 0 auto;
	color: white;
	padding: 0 1rem;
	text-decoration: none;
}

.links-out a:hover,
a:hover h4 {
	text-decoration: underline;
}

@media screen and (max-width: 720px) {
  /* 10 columns for larger screens */
  .item { width: 100%; margin-bottom: 20px; }
  #container {
		width: 90%;
		margin: 0 auto;
	}
}

@media screen and (min-width: 720px){
	ul li {
		display: inline;
		list-style: none;
	}
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: #FFFFFF;
  margin-bottom: 30px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
